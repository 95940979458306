<template>
  <v-sheet color="grey">
    <v-toolbar flat dense>
      <v-toolbar-title absolute="true" style="margin-left: 35%;">
        Consumer Score Log
      </v-toolbar-title>
      <v-spacer> </v-spacer>
      <v-icon @click="close()"> mdi-close </v-icon>
    </v-toolbar>
    <div>
      <v-sheet height="311px" color="grey-ligthen" tile>
        <v-container>
          <v-data-table
            :headers="header1"
            :items="item1"
            :loading="isLoad1"
            hide-default-footer
            dense
          >
          <template #no-data>
      <div :style="{ color: '#F18458' }">
        <h4>No Record Exist</h4>
      </div>
      </template>
          </v-data-table>
          <v-sheet height="20px"> </v-sheet>
          <v-data-table
            :headers="header2"
            :items="item2"
            :loading="isLoad2"
            hide-default-footer
            dense
          >
          <template #no-data>
      <div :style="{ color: '#F18458' }">
        <h4>No Record Exist</h4>
      </div>
      </template>
          </v-data-table>
          <v-sheet height="20px"> </v-sheet>
          <v-data-table
            :headers="header3"
            :items="item3"
            :loading="isLoad3"
            hide-default-footer
            dense
          >
          <template #no-data>
      <div :style="{ color: '#F18458' }">
        <h4>No Record Exist</h4>
      </div>
      </template>
          </v-data-table>
        </v-container>
      </v-sheet>
    </div>
  </v-sheet>
</template>

<script>
export default {
  components: {},
  name: "ConsumerReportLog",
  props: {
    data1: [],
    data2: [],
    data3: [],

  },
  data() {

    return {
      isLoad1:true,
    isLoad2:true,
    isLoad3:true,
      item1 : [],
      item2 : [],
      item3 : [],
      header1: [
        {
          text: "Reset Point Date",
          value: "reset_date",
          sortable: false,
          width: "120",
          class: "v-data-table-header",
        },
        {
          text: "Reset Point By",
          value: "reset_by",
          sortable: false,
          width: "120",
          class: "v-data-table-header",
        },
      ],
      header2: [
        {
          text: "Reset Bag Date",
          value: "bagupdate_date",
          sortable: false,
          width: "120",
          class: "v-data-table-header",
        },
        {
          text: "Reset Bag By",
          value: "bagupdate_by",
          sortable: false,
          width: "120",
          class: "v-data-table-header",
        },
      ],
      header3: [
        {
          text: "Bonus Point Add Date",
          value: "add_bonus_point_date",
          sortable: false,
          width: "120",
          class: "v-data-table-header",
        },
        {
          text: "Bonus Point Add By",
          value: "add_bonus_point_by",
          sortable: false,
          width: "120",
          class: "v-data-table-header",
        },
      ],
      data1: [],
      data2: [],
      data3: [],
    };
  },
  methods: {
    close: function () {
      this.item1 = []
      this.item2 = []
      this.item3 = []
      this.$emit("close");
    },
    
  },
  watch: {
      data1 : function(){
        if(this.data1.length == 0){
            this.isLoad1 = true
        }

        else if(this.data1.length > 0 && this.data1[0]==null){
            this.isLoad1 = false
            this.item1 = []
        }
        else{
          this.item1 = this.data1
          this.isLoad1 = false
        }
      },
    data2 : function(){
        if(this.data2.length == 0){
            this.isLoad2 = true
        }

        else if(this.data2.length > 0 && this.data2[0]==null){
            this.isLoad2 = false
            this.item2 = []

        }
        else{
          this.item2 = this.data2
          this.isLoad2 = false
        }
      },
    data3 : function(){
        if(this.data3.length == 0){
            this.isLoad3 = true
        }

        else if(this.data3.length > 0 && this.data3[0]==null){
            this.isLoad3 = false
            this.item3 = []

        }
        else{
          this.item3 = this.data3
          this.isLoad3 = false
        }
      },
    },
};
</script>
