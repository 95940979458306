<template>
  <div>
  <v-sheet color="grey-ligthen-3">
    <div>
      <div style="position: sticky; top: 0;">
        <v-toolbar flat dense >
        <v-toolbar-title style="left: 40%;position: absolute;">Consumer Scoring Report History</v-toolbar-title>

        <v-spacer></v-spacer>
        <v-btn icon dark color="primary" @click="closeDialogue">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-toolbar>
      </div>
      
      <div>
      <v-data-table
        :headers="headers"
        item-key="id"
        :page.sync="page"
        :items="history_data"
        :options.sync="options"
        :server-items-length="totalHistory"
        :loading="isLoad"
        class="elevation-1"
        hide-default-footer
        @page-count="pageCount = $event"
      >
        <!-- <template v-slot:top>
          
        </template> -->
      </v-data-table>
      </div>
      <div class="text-center pt-4 pb-4">
        <Pagination
          :isloading="isLoad"
          :startRecord="startRecord"
          :currentPage="currentPage"
          :lastPage="lastPage"
          :lastRecord="lastRecord"
          :totRecords="totHistRecords"
          :isCurrentPageClass="isCurrentPageClass"
          :perpage="perpage"
          :getLastPageClass="getLastPageClass"
          :totPage="totPage"
          :getPages="getPages"
          @handlePerPage="handlePerPage"
          @paginate="paginate"
          @last="last"
          @getDataByPage="getDataByPage"
          :showPerPage="showPerPage"
        />
      </div>
    </div>
  </v-sheet>
  </div>
</template>

<script>
import Pagination from "./Pagination.vue";

export default {
  name: "HistoryTable",
  props: {
    history_data: [],
    histitemid: Number,
    totalHistory: Number,
    histpage: Number,
    totHistRecords: Number,
    histoptions: Object,
    historydialog: Boolean,
    isLoad: Boolean,
  },
  components: { Pagination},
  computed: {
    //pagination computed methods
    startRecord: function () {
      return parseInt(this.lastRecord) - parseInt(this.perpage) + parseInt(1);
    },
    lastRecord: function () {
      return this.perpage * this.currentPage;
    },
    isCurrentPageClass: function () {
      return this.currentPage === 1 ? "" : "notactivespan";
    },
    getLastPageClass: function () {
      return parseInt(this.lastPage) === parseInt(this.currentPage)
        ? ""
        : "notactivespan";
    },
    totPage: function () {
      return Math.ceil(parseInt(this.totHistRecords) / parseInt(this.perpage));
    },
    getPages: function () {
      return this.$utils.getPages(
        this.lastPage,
        this.currentPage,
        this.totPage
      );
    },
    currentPage: {
      get: function () {
        return this.page;
      },
      set: function (Value) {
        this.page = Value;
      },
    },
    lastPage: function () {
      return Math.ceil(parseInt(this.totHistRecords) / parseInt(this.perpage));
    },
    endIndex: function () {
      if (this.page == 1) {
        return this.perpage;
      } else {
        return this.perpage * this.page;
      }
    },
    startIndex: function () {
      if (this.page == 1) {
        return this.perpage * 0;
      } else {
        return this.endIndex - this.perpage;
      }
    },
    ///////
  },
  data() {
    return {
      showPerPage: true,
      perpage: 50,
      page: this.histpage,
      options: this.histoptions,
      headers: [
        // {
        //   text: "Sr. No.",
        //   value: "id",
        //   sortable: false,
        //   class: "v-data-table-header",
        // },
        {
          text: "Insert Date",
          value: "insert_date",
          sortable: false,
          class: "v-data-table-header",
        },
        {
          text: "DM Pieces",
          value: "direct_mail_piece",
          sortable: false,
          class: "v-data-table-header",
        },
        {
          text: "DM Points",
          value: "direct_mail_point",
          sortable: false,
          class: "v-data-table-header",
        },
        {
          text: "Email Pieces",
          value: "email_piece",
          sortable: false,
          class: "v-data-table-header",
        },
        {
          text: "Email Point",
          value: "email_piece_point",
          sortable: false,
          class: "v-data-table-header",
        },
        {
          text: "Digital Points",
          value: "digital_point",
          sortable: false,
          class: "v-data-table-header",
        },
        {
          text: "Total Points",
          value: "total_point",
          sortable: false,
          class: "v-data-table-header",
        },
        {
          text: "Bonus Points",
          value: "bonus_point",
          sortable: false,
          class: "v-data-table-header",
        },
        {
          text: "Bags Remaining",
          value: "bag_remaining",
          sortable: false,
          class: "v-data-table-header",
        },
      ],
      
    };
  },
  methods: {
    getDataByPage(value) {
      this.page = value;
      this.$emit("getHistData", this.histitemid, this.page);
    },
    handlePerPage(value) {
      console.log(value);
      this.perpage = value;
      this.$emit("getHistData", this.histitemid, this.page);
    },
    paginate(n) {
      console.log(n);
      this.page = n === 0 ? 1 : parseInt(this.currentPage) + parseInt(n);
      this.$emit("getHistData", this.histitemid, this.page);
    },
    last(value) {
      console.log(value);
      this.page = this.lastPage;
      this.$emit("getHistData", this.histitemid, this.page);
    },
    closeDialogue() {
      this.currentPage = 1;
      this.$emit("clsdlg", this.isLoad);
    },
  },
  watch : {
    history_data : function(){
      if (this.history_data.length == 0){
        this.isLoad = true
      }else{
        this.isLoad = false
      }
    }
  },
  // beforeMount(){
  //   console.warn(this.isLoad)
  //   this.isLoad = true
    
  // },
  // watch : {
  //   history_data(){
  //     if (this.history_data != []){
  //       this.isLoad = false
  //     }
  //   }
  // },
  // beforeUnmount(){
  //   this.isLoad = true
  // }
};
</script>
